import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay, MonetizationOn, Call } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import ConfirmaCpf from "../ConfirmaCpf";
import { AuthContext } from "../../context/Auth/AuthContext";
import  request from "request";
import CloseTicketModal from "../CloseTicketModal";
import { SpyModeContext } from "../../context/SpyMode/SpyModeContext";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	slotModal: {
		display: "none"
	}
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const [modalOpen, setModalOpen] = useState(false);
	const [closeTicketModalOpen, setCloseTicketModalOpen] = useState(false);
	const [statusTicket, setStatusTicket] = useState(false);

	const { spyMode, setSpyMode } = useContext(SpyModeContext);

	const isMounted = useRef(true);
	const [ticketTransfer, setTicketTransfer] = useState("disabled");

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const response = await api.get(`/settings`);
				if (isMounted.current) {					
					const ticketTransferSetting = response.data?.find((e) => e.key === "ticketTransfer");					
					setTicketTransfer(ticketTransferSetting?.value);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchSettings();

		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setSpyMode(true);
			setLoading(false);
			toastError(err);
		}
	};

	const realizarLigacao = async (numero) => {
		toast.warn(`Disparando ligação...`);
		try {
			var dispararLigacao = await executarRequest(`https://api-lamp.lampzap.com.br/ligacao/o1KVZDG6in5Hc1InPrGBTR4ROjh5kzj4/${user.UsuUsuarioAD}/${numero}`, null, 10000);
			if(dispararLigacao.status === 1){
				var json = JSON.parse(dispararLigacao.retorno);
				if(json.status === true){
					toast.success(`A ligação foi disparada com sucesso!`);
				}else{
					toast.error(json.mensagem);
				}
			}else{
				toast.error(`Erro ao disparar (2)! Contate o suporte!`);
			}
		} catch (error) {
			toast.error(`Erro ao disparar! Contate o suporte!`);
		}
		
	};
	 const executarRequest = async (Url, Post, tempoMaximo) => {
		let resultado = undefined;
		return new Promise(function (resolve, reject) {
			request({
				url: Url,
				method: Post != null ? 'POST' : 'GET',
				formData: Post,
				timeout: tempoMaximo
			}, function (error, response, body) {
				if(!error){
					resultado = {
						'status': 1,
						'msg': 'Consultado!',
						'retorno': body
					};
				}else{
					resultado = {
						'status': 0,
						'msg': 'Erro de conexão com a intregração! (1) ' + error
					};
				}
				resolve(resultado);
			});
		});
	};

	return (
		<div className={classes.actionButtons}>
			{!ticket?.contact?.isBlocked && (
				<>
					{ticket.status === "closed" && !ticket.isGroup && (
						<ButtonWithSpinner
							loading={loading}
							startIcon={<Replay />}
							size="small"
							onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.reopen")}
						</ButtonWithSpinner>
					)}
	
					<IconButton
						size="small"
						onClick={() => setModalOpen(true)}
					>
						<MonetizationOn />
					</IconButton>
	
					<IconButton
						size="small"
						onClick={() => realizarLigacao(ticket.contact.number)}
					>
						<Call />
					</IconButton>
	
					{ticket.status === "open" && !ticket.isGroup && (
						<>
							<IconButton
								loading={loading}
								size="small"
								onClose={() => setCloseTicketModalOpen(false)}
								onClick={() => {
									setStatusTicket("pending");
									setCloseTicketModalOpen(true);
								}}
								disabled={spyMode}
							>
								<Replay />
							</IconButton>
	
							<ButtonWithSpinner
								loading={loading}
								size="small"
								variant="contained"
								color="primary"
								onClick={() => {
									setStatusTicket("closed");
									setCloseTicketModalOpen(true);
								}}
								disabled={spyMode}
							>
								{i18n.t("messagesList.header.buttons.resolve")}
							</ButtonWithSpinner>
							
							{((user.profile === "admin" || user.profile === "superadmin") || ticketTransfer === "enabled") && (
								<>
								<IconButton onClick={handleOpenTicketOptionsMenu} disabled={spyMode}>
									<MoreVert />
								</IconButton>
							
	
								<TicketOptionsMenu
									ticket={ticket}
									anchorEl={anchorEl}
									menuOpen={ticketOptionsMenuOpen}
									handleClose={handleCloseTicketOptionsMenu}
								/>
								</>
							)}
	
							<div className={classes.slotModal}>
								<ConfirmaCpf
									open={modalOpen}
									onClose={() => setModalOpen(false)}
									contactId={ticket.contact.id}
								/>
							</div>
	
							<div className={classes.slotModal}>
								<CloseTicketModal
									open={closeTicketModalOpen}
									onClose={() => setCloseTicketModalOpen(false)}
									ticketId={ticket.id}
									status={statusTicket}
								/>
							</div>
						</>
					)}
	
					{ticket.status === "pending" && !ticket.isGroup && (
						<ButtonWithSpinner
							loading={loading}
							size="small"
							variant="contained"
							color="primary"
							onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
						>
							{i18n.t("messagesList.header.buttons.accept")}
						</ButtonWithSpinner>
					)}
				</>
			)}
		</div>
	);
	
};

export default TicketActionButtons;
